<template>
    <div class="flex items-center">
      <img 
        v-if="!isDarkMode"
        src="@/assets/icons/isologo-light.svg"
        :alt="$t('App_Title')"
        class="w-10 h-10 mr-3 text-customPurple"
      />
      <img 
        v-else
        src="@/assets/icons/isologo-dark.svg"
        :alt="$t('App_Title')"
        class="w-10 h-10 mr-3 text-customPurple"
      />
      <span class="font-bold text-lg lg:text-xl text-lightText dark:text-darkText">{{ $t('App_Title') }}</span>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  
  const isDarkMode = ref(false);
  
  const updateTheme = () => {
    isDarkMode.value = document.documentElement.classList.contains('dark');
  };
  
  onMounted(() => {
    updateTheme();
    const observer = new MutationObserver(() => {
      updateTheme();
    });
  
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  });
  </script>
  