<template>
  <div class="w-full flex justify-end pr-6 delete-chat-button">
    <button @click="emitDeleteChat"
      class="flex items-center space-x-2 text-[rgba(176,12,12,0.69)] font-lato font-semibold text-sm bg-transparent hover:text-red-700 transition duration-300">
      <img src="@/assets/icons/delete-icon.svg" alt="Delete Icon" class="w-4 h-4" />
      <span>{{ $t('Body_Section2_DeleteButtonText') }}</span>
    </button>
  </div>
</template>

<script setup>
const emit = defineEmits(['delete-chat']);

const emitDeleteChat = () => {
  emit('delete-chat');
};
</script>
<style scoped>
.delete-chat-button {
  position: relative;
  bottom: 3vh; /* Se ajusta la distancia desde la parte inferior */
  right: 1vh; /* Se ajusta la distancia desde la parte derecha */
}
</style>
