import { getCurrentInstance, toRefs } from "vue";
import { InteractionStatus } from "@azure/msal-browser";
/**
 * Defines the structure of the MSAL context used in Vue components.
 * @typedef {Object} MsalContext
 * @property {import("@azure/msal-browser").PublicClientApplication} instance - An instance of MSAL's PublicClientApplication.
 * @property {Ref<Array<AccountInfo>>} accounts - Reactive reference to the array of account information.
 * @property {Ref<InteractionStatus>} inProgress
 */

/**
 * Provides MSAL (Microsoft Authentication Library) context within a Vue component.
 * @returns {MsalContext} - MSAL context with instance, accounts, and interaction status.
 * @throws Will throw an error if useMsal() is called outside the setup function of a component or if the msalPlugin is not installed.
 */
export function useMsal() {
    const internalInstance = getCurrentInstance();
    if (!internalInstance) {
        throw new Error("useMsal() cannot be called outside the setup() function of a component");
    }

    // Extracts MSAL state from the global properties where it should be set by the msalPlugin
    const { instance, accounts, inProgress } = toRefs(internalInstance.appContext.config.globalProperties.$msal);

    if (!instance || !accounts || !inProgress) {
        throw new Error("Please install the msalPlugin");
    }

    // Initialize MSAL instance if it's still in the startup process
    if (inProgress.value === InteractionStatus.Startup) {
        instance.value.initialize().then(() => {
            instance.value.handleRedirectPromise().catch(() => {
                // Errors should be handled by listening to the LOGIN_FAILURE event
                return;
            });
        });
    }

    return {
        instance: instance.value,
        accounts,
        inProgress
    }
}
