<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
/* Estilos para Chrome, Safari, Edge */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #e4e6eb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c6c7cd;
  border-radius: 999px;
  border: 3px solid #f0f1f5;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b0b1b7;
}

::-webkit-scrollbar-thumb:active {
  background-color: #999aa3;
}

/* Estilos para Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c6c7cd #e4e6eb;
}

</style>
