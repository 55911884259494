import { ref, watch } from "vue";
import { useMsalAuthentication } from "./useMsalAuthentication";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "@/authConfig";
// import { jwtDecode } from "jwt-decode";

/**
 * Provides a reactive reference to get the user info (roles, groups).
 * @returns {Ref<string[]>} - A reactive reference to the user's roles.
 */
export function useUserInfo() {
    const roles = ref([]);
    const groups = ref([]);

    const result = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    /**
     * 
     * @param {Ref<import("@azure/msal-browser").AuthenticationResult>} authResult 
     */
    const getUserInfo = async (authResult) => {
        roles.value = [];
        groups.value = [];

        // const tokenCode = authResult.accessToken;
        // const token = jwtDecode(tokenCode);
        let token = authResult.idTokenClaims;

        if (authResult) {
            roles.value = token.roles;
            groups.value = token.groups;
        }
    };

    /**
     * Watch the useMsalAuthentication result for changes, get accessToken and call api to get user roles.
     */
    watch(result.result, (newResult) => {
        if (newResult) {
            getUserInfo(newResult);
        }
    }, { deep: true });

    return { roles, groups };
}