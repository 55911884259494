import { createRouter, createWebHistory } from 'vue-router';
import { registerGuard } from "./Guard";
import SalesCopilotMainFramework from '../components/SalesCopilotMainFramework.vue';
import UnauthorizedPage from '@/components/pages/UnauthorizedPage.vue';
import FailedPage from '@/components/pages/FailedPage.vue';

const routes = [
  {
    path: '/',
    component: SalesCopilotMainFramework,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/failed',
    component: FailedPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/unauthorized',
    component: UnauthorizedPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

registerGuard(router);

export default router;