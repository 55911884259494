<template>
  <div class="relative flex items-center w-1/2 justify-end px-3" ref="dropdownRef">
    <button 
      @click="toggleDropdown"
      class="flex items-center text-lightText dark:text-darkText cursor-pointer focus:outline-none"
    >
      <span class="mr-2">{{ $t("Body_Section1_ChooseLabel") }}</span>
      <img 
        v-if="!isDarkMode" 
        src="@/assets/icons/light-arrow-up.svg" 
        alt="Arrow Up Icon"
        class="inline-block w-5 h-5"
      />
      <img 
        v-else 
        src="@/assets/icons/dark-arrow-up.svg" 
        alt="Arrow Up Icon"
        class="inline-block w-5 h-5"
      />
    </button>

    <div 
      v-if="dropdownOpen"
      class="absolute bottom-full mb-2 w-full bg-customPurple dark:bg-lightContainerBorder rounded-sm shadow-lg z-10 p-4 text-lightText dark:text-darkText"
    >
      <ul class="space-y-2">
        <li 
          v-for="(question, index) in translatedQuestions"
          :key="index"
          @click="handleSelectQuestion(question)"
          class="cursor-pointer hover:bg-customPurpleHover dark:hover:bg-darkBackgroundContainer px-4 py-2 rounded-sm"
        >
          {{ question.text }}
        </li>
      </ul>
      <div class="text-right mt-4">
        <button
          @click="editQuestions"
          class="text-white hover:text-gray-300 font-semibold"
        >
          {{ $t("Body_Section1_EditLabel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  questions: Array,
});

const emit = defineEmits(['selectQuestion', 'edit']);

const { t } = useI18n();

const dropdownOpen = ref(false);
const isDarkMode = ref(false);
const dropdownRef = ref(null);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const handleSelectQuestion = (question) => {
  emit('selectQuestion', question);
  dropdownOpen.value = false;
};

const editQuestions = () => {
  emit('edit');
  dropdownOpen.value = false;
};

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

const updateTheme = () => {
  isDarkMode.value = document.documentElement.classList.contains('dark');
};

onMounted(() => {
  updateTheme();
  const observer = new MutationObserver(() => {
    updateTheme();
  });
  observer.observe(document.documentElement, {
    attributes: true,
    attributeFilter: ['class'],
  });

  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const translatedQuestions = computed(() => {
  return props.questions.map(question => ({
    ...question,
    text: question.text || t(question.textKey),
  }));
});
</script>
