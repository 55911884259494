<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div 
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      @click.self="closeModal"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true" @click="closeModal">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div 
        class="inline-block align-bottom bg-white dark:bg-darkBackground px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        @click.stop
      >
        <h2 class="font-lato font-normal text-[16px] leading-[24px] text-gray-900 dark:text-gray-100">
          {{ $t('Modal_EditQuestions_Title') }}
        </h2>
        <p class="mt-1 font-lato font-normal text-[14px] leading-[24px] text-gray-600 dark:text-gray-400">
          {{ $t('Modal_EditQuestions_Description') }}
        </p>
        <div class="mt-4">
          <nav class="-mb-px flex space-x-8">
            <button
              v-for="(option, index) in tabs"
              :key="index"
              @click="activeTab = index"
              :class="activeTab === index ? 'border-customPurple text-customPurple' : 'border-transparent text-gray-500 dark:text-gray-300 hover:text-gray-700 hover:border-gray-300'"
              class="whitespace-nowrap py-4 px-1 border-b-2 font-lato font-normal text-[16px] leading-[24px]"
            >
              {{ $t('Modal_EditQuestions_Tab_Question') }} {{ index + 1 }}
            </button>
          </nav>
        </div>
        <div class="mt-4">
          <div v-for="(question, index) in editableQuestions" :key="index" v-show="activeTab === index" class="mb-4">
            <label class="block font-lato font-normal text-[16px] leading-[24px] text-gray-700 dark:text-gray-300">
              {{ $t('Modal_EditQuestions_Label_Question') }}
            </label>
            <input 
              type="text" 
              v-model="question.text"
              @input="question.userEdited = true"
              class="mt-1 p-2 block w-full shadow-sm sm:text-[16px] leading-[24px] font-lato font-normal border-darkText dark:border-darkBorder rounded-md bg-lightBackgroundContainer dark:bg-darkBorder text-darkBorder dark:text-darkContainerBorder" 
            />
            <label class="block font-lato font-normal text-[16px] leading-[24px] text-gray-700 dark:text-gray-300 mt-2">
              {{ $t('Modal_EditQuestions_Label_Category') }}
            </label>
            <select 
              v-model="question.categoryId" 
              class="mt-1 p-2 block w-full shadow-sm sm:text-[16px] leading-[24px] font-lato font-normal border-darkText dark:border-darkBorder rounded-md bg-lightBackgroundContainer dark:bg-darkBorder text-darkBorder dark:text-darkContainerBorder"
            >
              <option v-for="category in categories" :value="category.id" :key="category.id">
                {{ $t(category.text) }}
              </option>
            </select>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 flex justify-end space-x-2">
          <button 
            type="button" 
            class="inline-flex justify-center rounded-md px-4 py-2 font-lato font-normal text-[16px] leading-[24px] text-lightText dark:text-darkText hover:dark:text-customPurple hover:text-customPurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-[16px] sm:leading-[24px]" 
            @click="closeModal"
          >
            {{ $t('Modal_EditQuestions_Button_Cancel') }}
          </button>
          <button 
            type="button" 
            class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-customPurple text-[16px] leading-[24px] font-lato font-normal text-white hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-[16px] sm:leading-[24px]" 
            @click="savePreselectedQuestions"
          >
            {{ $t('Modal_EditQuestions_Button_Accept') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const props = defineProps({
  initialQuestions: {
    type: Array,
    required: true,
  },
  categories: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close', 'questionsUpdated']);

const editableQuestions = ref([]);
const activeTab = ref(0);

const tabs = ref([]);

const loadQuestionsFromStorage = () => {
  const storedQuestions = JSON.parse(localStorage.getItem(`preselectedQuestions_${locale.value}`)) || [];

  editableQuestions.value = props.initialQuestions.map((question, index) => {
    const storedQuestion = storedQuestions.find((q) => q.textKey === question.textKey);
    return {
      ...question,
      text: storedQuestion ? storedQuestion.text : t(question.textKey),
      userEdited: !!storedQuestion,
    };
  });

  tabs.value = editableQuestions.value.map((_, index) => `Pregunta ${index + 1}`);
};

const savePreselectedQuestions = () => {
  localStorage.setItem(`preselectedQuestions_${locale.value}`, JSON.stringify(editableQuestions.value));
  emit('questionsUpdated', editableQuestions.value);
  emit('close'); 
};

watch(locale, () => {
  loadQuestionsFromStorage();
});

onMounted(() => {
  loadQuestionsFromStorage();
});

const closeModal = () => {
  emit('close');
};
</script>
