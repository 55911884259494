<template>
  <div class="fixed z-50 inset-0 flex items-center justify-center">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75 dark:bg-black dark:opacity-50"></div>
    </div>
    <div class="inline-block align-bottom bg-white dark:bg-darkBackground px-8 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-3xl sm:w-full sm:p-6 border dark:border-darkBorder h-[75vh] flex flex-col justify-between">
      <div class="mt-2 flex-grow overflow-y-auto pb-16"> 
        <p class="font-lato font-normal text-[14px] leading-[24px] text-gray-500 dark:text-gray-300 mb-4" v-html="$t('Proposal_Message_Introduction_Html')"></p>
        <h3 class="font-lato font-normal text-[16px] leading-[24px] mb-4 text-lightText dark:text-darkText">
          {{ $t('Proposal_Message_Title') }}
        </h3>
        <div class="mt-4">
          <div class="tabs mb-4 flex justify-start">
            <button @click="activeTab = 'examples'" :class="activeTab === 'examples' ? 'text-customPurple' : ''" class="mr-4 bg-transparent border-none px-4 py-2 font-lato font-normal text-[16px] leading-[24px] cursor-pointer hover:text-customPurple">
              {{ $t('Proposal_Message_Input_Example_Title') }}
            </button>
            <button @click="activeTab = 'text'" :class="activeTab === 'text' ? 'text-customPurple' : ''" class="bg-transparent border-none px-4 py-2 font-lato font-normal text-[16px] leading-[24px] cursor-pointer hover:text-customPurple">
              {{ $t('Proposal_Message_Title') }}
            </button>
          </div>
          <div v-if="activeTab === 'examples'" class="space-y-4">
            <div v-for="example in examples" :key="example.title">
              <div class="flex justify-between items-center">
                <b class="font-lato font-normal text-[16px] leading-[24px] text-lightText dark:text-darkText">{{ example.title }}</b>
                <img 
                  v-if="!example.expanded"
                  @click="toggleExample(example)"
                  src="@/assets/icons/arrow-down.svg"
                  alt="Expand Icon"
                  class="w-6 h-6 cursor-pointer"
                />
                <img 
                  v-else 
                  @click="toggleExample(example)"
                  src="@/assets/icons/arrow-up.svg"
                  alt="Collapse Icon"
                  class="w-6 h-6 cursor-pointer"
                />
              </div>
              <p v-if="example.expanded" class="font-lato font-normal text-[14px] leading-[24px] text-lightText dark:text-darkText" v-html="example.text"></p>
            </div>
          </div>
          <div v-if="activeTab === 'text'" class="mt-4 border dark:border-darkBorder p-4 rounded-md">
            <p class="font-lato font-normal text-[14px] leading-[24px] text-lightText dark:text-darkText" v-html="$t('Proposal_Message_Html')"></p>
          </div>
        </div>
      </div>
      <div class="mt-4 sm:mt-4 bg-white dark:bg-darkBackground sticky bottom-0 left-0 w-full">
        <button 
          type="button" 
          class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-customPurple dark:bg-customPurple font-lato font-normal text-[16px] leading-[24px] text-white hover:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-[16px] sm:leading-[24px]"
          @click="closeModal"
        >
          {{ $t('Close_Button_Text') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['close']);
const { t } = useI18n();

const activeTab = ref('examples');

const examples = ref([
  {
    title: t('Proposal_Message_Input_Example1_Title'),
    text: t('Proposal_Message_Input_Example1_Text_Html'),
    expanded: false
  },
  {
    title: t('Proposal_Message_Input_Example2_Title'),
    text: t('Proposal_Message_Input_Example2_Text_Html'),
    expanded: false
  },
  {
    title: t('Proposal_Message_Input_Example3_Title'),
    text: t('Proposal_Message_Input_Example3_Text_Html'),
    expanded: false
  },
  {
    title: t('Proposal_Message_Input_Example4_Title'),
    text: t('Proposal_Message_Input_Example4_Text_Html'),
    expanded: false
  },
  {
    title: t('Proposal_Message_Input_Example5_Title'),
    text: t('Proposal_Message_Input_Example5_Text_Html'),
    expanded: false
  },
  {
    title: t('Proposal_Message_Input_Example6_Title'),
    text: t('Proposal_Message_Input_Example6_Text_Html'),
    expanded: false
  },
  {
    title: t('Proposal_Message_Input_Example7_Title'),
    text: t('Proposal_Message_Input_Example7_Text_Html'),
    expanded: false
  }
]);

const toggleExample = (example) => {
  examples.value.forEach(e => {
    if (e !== example) e.expanded = false;
  });
  example.expanded = !example.expanded;
};

const closeModal = () => {
  emit('close');
};
</script>
