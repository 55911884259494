<template>
    <main class="min-h-screen w-full bg-white">
        <!-- header page -->
        <div id="content-wrap">
            <AppHeader @toggleSidebar="toggleSidebar" />

            <!-- Main content section -->
            <section class="flex flex-col items-center justify-center min-h-screen bg-white pt-40">
                <div class="max-w-screen-md w-full px-2 md:px-2 text-center">
                    <p class="mb-4 text-3xl md:text-4xl font-bold text-gray-900">
                        {{ $t("UnauthorizedPage_Title") }}
                    </p>
                    <p class="mb-4 text-lg font-light text-gray-500" v-html="$t('UnauthorizedPage_Subtitle')">
                    </p>
                </div>

                <!-- Stripe pricing table -->
                <stripe-pricing-table v-if="isAuthenticated && (roles == null || roles.length <= 0)"
                    :pricing-table-id="pricingTableId" :publishable-key="pricingTableKey" class="mt-8 w-full flex-grow">
                </stripe-pricing-table>
            </section>
        </div>
    </main>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { useMsal } from '@/composables/useMsal';
import { useUserInfo } from '@/composables/useUserInfo';
import {
    VUE_APP_STRIPE_PRICING_TABLE_KEY,
    VUE_APP_STRIPE_PRICING_TABLE_ID_EN,
    VUE_APP_STRIPE_PRICING_TABLE_ID_ES
} from '@/constants';
import { useI18n } from 'vue-i18n';
import AppHeader from '../layout/AppHeader.vue';

const { accounts } = useMsal();
const { roles } = useUserInfo();
const { locale } = useI18n();

const pricingTableId = computed(() => {
    return locale.value === 'es' ? VUE_APP_STRIPE_PRICING_TABLE_ID_ES : VUE_APP_STRIPE_PRICING_TABLE_ID_EN;
});

const pricingTableKey = ref(VUE_APP_STRIPE_PRICING_TABLE_KEY);

const isAuthenticated = computed(() => {
    return accounts.value.length > 0;
});

onMounted(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = false;
    document.head.appendChild(script);
});
</script>