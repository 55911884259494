<template>
    <button
      class="absolute right-5 top-1/2 transform -translate-y-1/2 inline-flex justify-center h-7 w-7 rounded-full text-center text-xs font-medium text-lightText dark:text-darkText"
      type="button"
      @click="handleClick"
    >
      <img 
        v-if="!isResponding && !isDarkMode" 
        src="@/assets/icons/send-icon-light.svg" 
        alt="Send Icon Light"
        class="h-7 w-7 m-auto"
      />
      <img 
        v-else-if="!isResponding && isDarkMode" 
        src="@/assets/icons/send-icon-dark.svg" 
        alt="Send Icon Dark"
        class="h-7 w-7 m-auto"
      />
      <img 
        v-else 
        src="@/assets/icons/stop-icon.svg" 
        alt="Stop Icon"
        class="h-7 w-7 m-auto"
      />
    </button>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  
  const props = defineProps({
    isResponding: {
      type: Boolean,
      default: false,
    },
  });
  
  const emit = defineEmits(['action']);
  
  const isDarkMode = ref(false);
  
  onMounted(() => {
    isDarkMode.value = document.documentElement.classList.contains('dark');
  
    const observer = new MutationObserver(() => {
      isDarkMode.value = document.documentElement.classList.contains('dark');
    });
  
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  });
  
  const handleClick = () => {
    if (props.isResponding) {
      emit('action', 'stop');
    } else {
      emit('action', 'send');
    }
  };
  </script>
  