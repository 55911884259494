<template>
  <div class="flex items-center relative py-3 px-3 rounded-lg bg-lightBackground dark:bg-darkBackground transition-colors duration-300">
    <ResizableTextarea
      :modelValue="userText"
      :isResponding="isResponding"
      @update:modelValue="updateUserText"
      @send="sendMessage"
    />
    <SendStopButton :isResponding="isResponding" @action="handleAction" />
  </div>
</template>

<script setup>
import ResizableTextarea from './ResizableTextarea.vue';
import SendStopButton from './SendStopButton.vue';

const props = defineProps({
  userText: {
    type: String,
    default: '',
  },
  isResponding: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:userText', 'send', 'stop']);

const updateUserText = (value) => {
  if (typeof value === 'string') {
    emit('update:userText', value);
  } else {
    console.error("El valor de userText no es una cadena de texto:", value);
  }
};

const sendMessage = (message) => {
  if (typeof message === 'string' && message.trim()) {
    emit('send', message.trim());
  } else {
    console.error("El valor de message no es un string:", message);
  }
};

const handleAction = (action) => {
  if (action === 'send') {
    sendMessage(props.userText);
  } else {
    emit('stop');
  }
};
</script>
