import { msalInstance, loginRequest } from "../authConfig";
import { InteractionType } from "@azure/msal-browser";

/**
 * Registra un guardia de navegación para el enrutador.
 * @param {import("vue-router").Router} router - El router de Vue-router utilizado para manejar la navegación.
 */
export function registerGuard(router) {
    router.beforeEach(async (to, _from) => {
        await msalInstance.handleRedirectPromise();

        const request = {
            ...loginRequest,
            redirectStartPage: to.fullPath
        };

        if (to.query.purchase) {
            request.redirectStartPage = '/';

            const tokenRefreshed = await forceRefreshAuthToken(msalInstance, request);
            if (!tokenRefreshed) {
                // Si no se puede refrescar el token, redirigir al usuario a la página de inicio de sesión
                await msalInstance.loginRedirect(request);
                return;
            }

            return;
        }

        const loggedIn = await isAuthenticated(msalInstance, InteractionType.Redirect, request);

        if (to.meta.requiresAuth) {
            const shouldProceed = loggedIn;
            if (!shouldProceed)
                return '/unauthorized';

            const roles = await userRoles(msalInstance);
            if (roles.length == 0)
                return '/unauthorized';
        }

        return true;
    });
}

/**
 * Verifica si el usuario está autenticado o inicia una nueva autenticación si es necesario.
 * @param {import("@azure/msal-browser").PublicClientApplication} instance - La instancia de MSAL usada para la autenticación.
 * @param {InteractionType} interactionType - El tipo de interacción de autenticación, puede ser Popup o Redirect.
 * @param {PopupRequest|RedirectRequest} loginRequest - La solicitud de inicio de sesión configurada.
 * @returns {Promise<boolean>} Retorna `true` si el usuario está autenticado, de lo contrario `false`.
 */
export async function isAuthenticated(instance, interactionType, loginRequest) {
    try {
        const accounts = instance.getAllAccounts();

        if (accounts.length > 0) {
            return true;
        }

        if (interactionType === InteractionType.Popup) {
            await instance.loginPopup(loginRequest);
            return true;
        } else if (interactionType === InteractionType.Redirect) {
            await instance.loginRedirect(loginRequest);
            return true;
        }

        return false;
    } catch (error) {
        console.error("isAuthenticated error", error);
        return false;
    }
}


/**
 * Verifica si el usuario está autenticado o inicia una nueva autenticación si es necesario.
 * @param {import("@azure/msal-browser").PublicClientApplication} instance - La instancia de MSAL usada para la autenticación.
 * @returns {Promise<string>} 
 */
export async function userRoles(instance) {
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
        console.warn("No accounts found, redirecting to login.");
        await instance.loginRedirect(loginRequest);
        return [];
    }

    try {
        const token = await instance.acquireTokenSilent(loginRequest);
        return token.idTokenClaims.roles || [];
    } catch (error) {
        console.error("Error acquiring token silently for roles", error);
        await instance.loginRedirect(loginRequest);
        return [];
    }
}


async function forceRefreshAuthToken(instance) {
    try {
        await instance.loginRedirect(loginRequest);
        return true;
    } catch (redirectError) {
        console.error("Error adquiriendo el token con redirección", redirectError);
        return false;
    }
}