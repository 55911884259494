import './assets/css/tailwind.css';
import { createApp } from 'vue';
import App from './App.vue';
import i18n from '@/plugins/i18n';
import router from './router/Router';
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";
import { vOnClickOutside } from '@vueuse/components';

async function initializeMSAL() {
    try {
        const navigationClient = new CustomNavigationClient(router);
        msalInstance.setNavigationClient(navigationClient);

        await msalInstance.initialize();
        await msalInstance.handleRedirectPromise();
        
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }

        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload;
                const account = payload.account;
                msalInstance.setActiveAccount(account);
            }
        });
    } catch (error) {
        console.error("Error initializing MSAL", error);
        await msalInstance.logoutRedirect();
    }
}

initializeMSAL().then(() => {
    const app = createApp(App);

    app.use(i18n);
    app.use(router);
    app.use(msalPlugin, msalInstance);
    
    app.directive('on-click-outside', vOnClickOutside);

    router.isReady().then(() => {
        app.mount('#app');
    });
});
