<template>
    <div id="settings-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true"
        class="fixed inset-0 z-50 flex-container items-center justify-center w-full h-full bg-black bg-opacity-50">
        <div class="bg-lightBackground dark:bg-darkBackground rounded-md shadow-lg w-full max-w-md">
            <div class="flex justify-between items-center p-6 border-b border-gray-200 dark:border-gray-600">
                <h3 class="font-lato font-normal text-[16px] leading-[24px] text-darkBorder dark:text-darkContainerBorder">
                    {{ $t('SettingsModal_Title') }}
                </h3>
                <button type="button" @click="closeModal"
                    class="text-gray-400 hover:text-gray-900 dark:hover:text-white disabled:text-gray-400 dark:disabled:text-gray-400 disabled:cursor-not-allowed" :disabled="isLoading">
                    <span class="sr-only">{{ $t('SettingsModal_Close') }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="px-6 py-4 space-y-6">
                <div class="flex flex-col space-y-4">
                    <label for="content-level" class="font-lato font-normal text-[16px] leading-[24px] text-darkBorder dark:text-darkContainerBorder">
                        {{ $t('SettingsModal_ContentLevel') }}
                    </label>
                    <div class="flex items-center justify-between">
                        <span class="font-lato font-normal text-[14px] leading-[24px] text-gray-600 dark:text-gray-400">{{ $t('SettingsModal_ContentLevel_Generic') }}</span>
                        <input id="content-level" type="range" min="0" max="1" v-model="contentLevel" step="1" 
                            class="w-full mx-4 h-2 bg-gray-200 appearance-none cursor-pointer dark:bg-gray-700 slider-custom disabled:cursor-not-allowed" :disabled="isLoading">
                        <span class="font-lato font-normal text-[14px] leading-[24px] text-gray-600 dark:text-gray-400">{{ $t('SettingsModal_ContentLevel_Sector') }}</span>
                    </div>
                </div>

                <div class="flex items-center space-x-3">
                    <input id="showDocsReferences" type="checkbox" v-model="showDocsReferences" 
                        class="w-4 h-4 text-lightContainerBorder border-gray-300 rounded focus:ring-lightContainerBorder dark:focus:ring-lightContainerBorder dark:bg-gray-700 disabled:cursor-not-allowed" :disabled="isLoading">
                    <label for="showDocsReferences" class="font-lato font-normal text-[14px] leading-[24px] text-darkBorder dark:text-darkContainerBorder">
                        {{ $t('SettingsModal_ShowDocsReferences') }}
                    </label>
                </div>
            </div>
            <div class="flex justify-end p-6 border-t border-gray-200 dark:border-gray-600 space-x-4">
                <button 
                    @click="closeModal"
                    class="py-2 px-4 font-lato font-normal text-[16px] leading-[24px] text-gray-900 bg-transparent hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 disabled:cursor-not-allowed disabled:bg-transparent dark:disabled:bg-transparent" :disabled="isLoading">
                    {{ $t('SettingsModal_Cancel') }}
                </button>
                <button 
                    @click="setSettings" 
                    class="py-2 px-4 font-lato font-normal text-[16px] leading-[24px] text-white bg-customPurple rounded-md hover:bg-customPurpleHover dark:bg-customPurple dark:hover:bg-customPurpleHover disabled:cursor-not-allowed dark:disabled:bg-customPurple disabled:bg-customPurple" :disabled="isLoading" >
                    {{ $t('SettingsModal_Save') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { VUE_APP_API_URL } from '@/constants';
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from '@/authConfig';

const { acquireToken, result } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
const isLoading = ref(false);
const showDocsReferences = ref(false);
const contentLevel = ref(0);
const emit = defineEmits(['close', 'save']);

const getSettings = async () => {
    isLoading.value = true;

    await acquireToken();

    const response = await fetch(`${VUE_APP_API_URL}/api/aci/settings`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${result.value.accessToken}`
        }
      });

    if(response.ok){
        const settings = await response.json();
        showDocsReferences.value = settings.showDocsReferences;
        contentLevel.value = settings.specificityLevel;
    }

    isLoading.value = false;
};

const setSettings = async () => {
    isLoading.value = true;

    const body = {
        showDocsReferences: showDocsReferences.value,
        specificityLevel: Number.parseInt(contentLevel.value)
    };

    await acquireToken();

    const response = await fetch(`${VUE_APP_API_URL}/api/aci/settings`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${result.value.accessToken}`
        },
        body: JSON.stringify(body)
    });

    isLoading.value = false;

    closeModal();
};

const closeModal = () => {
    emit('close');
};

defineExpose({ getSettings });

</script>

<style scoped>
.slider-custom::-webkit-slider-thumb {
    background-color: #7D7DD1;
    border: 2px solid #7D7DD1;
    border-radius: 50%;
    height: 16px;
    width: 16px;
}
.slider-custom::-moz-range-thumb {
    background-color: #7D7DD1;
    border: 2px solid #7D7DD1;
    border-radius: 50%;
    height: 16px;
    width: 16px;
}

.slider-custom::-ms-thumb {
    background-color: #7D7DD1;
    border: 2px solid #7D7DD1;
    border-radius: 50%;
    height: 16px;
    width: 16px;
}
.slider-custom:disabled {
    cursor: not-allowed;
}

.slider-custom:disabled::-webkit-slider-thumb {
    background-color: #cccccc;
    border-color: #cccccc;
}

.slider-custom:disabled::-moz-range-thumb {
    background-color: #cccccc;
    border-color: #cccccc;
}

.slider-custom:disabled::-ms-thumb {
    background-color: #cccccc;
    border-color: #cccccc;
}
.flex-container {
    display: flex;
}
</style>
