import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { ref, watch } from "vue";
import { useMsal } from "./useMsal";

/**
 * @typedef {Object} MsalAuthenticationResult
 * @property {function(requestOverride: (import("@azure/msal-browser").PopupRequest|import("@azure/msal-browser").RedirectRequest|import("@azure/msal-browser").SilentRequest)=): Promise<void>} acquireToken - Función para adquirir un token de autenticación.
 * @property {import("vue").Ref<import("@azure/msal-browser").AuthenticationResult|null>} result
 * @property {import("vue").Ref<import("@azure/msal-browser").AuthError|null>} error
 * @property {import("vue").Ref<boolean>} inProgress
 */


/**
 * Provides a reactive way to manage MSAL authentication in a Vue component.
 * @param {InteractionType} interactionType - The type of user interaction to handle authentication.
 * @param {import("@azure/msal-browser").PopupRequest|import("@azure/msal-browser").RedirectRequest|import("@azure/msal-browser").SilentRequest} request - The initial authentication request configuration.
 * @returns {MsalAuthenticationResult} The MSAL authentication result with reactive properties.
 */
export function useMsalAuthentication(interactionType, request) {
    const result = ref(null);
    const error = ref(null);
    const localInProgress = ref(false);

    const { instance, inProgress } = useMsal();

    const acquireToken = async (requestOverride) => {
        if (!localInProgress.value) {
            localInProgress.value = true;
            const tokenRequest = requestOverride || request;
    
            const accounts = instance.getAllAccounts();
            if (accounts.length === 0) {
                console.warn("No accounts found, redirecting to login.");
                await instance.loginRedirect(tokenRequest);
                return;
            }
    
            try {
                const response = await instance.acquireTokenSilent(tokenRequest);
                result.value = response;
                error.value = null;
            } catch (e) {
                if (inProgress.value !== InteractionStatus.None) {
                    return;
                }
    
                if (interactionType === InteractionType.Popup) {
                    instance.loginPopup(tokenRequest).then((response) => {
                        result.value = response;
                        error.value = null;
                    }).catch((e) => {
                        error.value = e;
                        result.value = null;
                    });
                } else if (interactionType === InteractionType.Redirect) {
                    await instance.loginRedirect(tokenRequest).catch((e) => {
                        error.value = e;
                        result.value = null;
                    });
                }
            }
            localInProgress.value = false;
        }
    }
    

    const stopWatcher = watch(inProgress, () => {
        if (!result.value && !error.value) {
            acquireToken();
        } else {
            stopWatcher();
        }
    });

    acquireToken();

    return {
        acquireToken,
        result,
        error,
        inProgress: localInProgress
    };
}

