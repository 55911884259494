import { createI18n } from "vue-i18n";

const messages = {
    'en': {
        App_Title: 'SAI Sales Mentor',
        Sidebar_Title1: 'Introduction',
        Sidebar_Text1: 'Sales Mentor: your personal assistant to excel in sales. It will guide you through every stage of the sales process, from prospecting clients, preparing for a visit, to creating persuasive proposals, handling objections, and closing deals.',

        Sidebar_Text1o: `
        How can you use Sales Mentor?<br><br>
        
        Resolve any questions about the most current and effective sales process. Ask in the General tab.<br><br>
        
        Key tools to optimize your sales: If you know the sales stage to which your query belongs, choose the tab and get a much more complete response.<br><br>
        
        <ol>
          <li><strong>Prospecting</strong>: Discover strategies to identify and capture potential clients.</li><br>
          <li><strong>Opening</strong>: Get ideas and guides on how to start conversations depending on whether they are cold, warm, or follow-up leads, whether inbound or outbound.</li><br>
          <li><strong>Prepare visits</strong>: Interact with Sales Mentor to get ideas, examples, and useful scenarios on all the information you should review and consider before starting a visit or call.</li><br>
          <li><strong>Prepare strategic questions to ask</strong>: Create questions that provoke reflection and do not raise barriers, obtaining valuable information and awakening the need for the client to buy.</li><br>
          <li><strong>Develop persuasive proposals</strong>: Create impactful value propositions. Then, ask to adapt them into an email format for quick sending.</li><br>
          <li><strong>Anticipate and overcome objections</strong>: Prevent possible objections and learn how to address them effectively.</li><br>
          <li><strong>Design closing strategies</strong>: Get ready with techniques to gain commitment and close the sale.</li><br>
          <li><strong>Plan and execute good follow-up</strong>: Create emails and strategies to properly follow up with your clients without seeming pushy or desperate.</li>
        </ol>
        `,            
        Sidebar_Title2: 'Quick start tutorial',
        Sidebar_Text2: 'Sales Mentor offers an intuitive and straightforward user experience. To get started, simply follow these two easy steps:',
        Sidebar_Text2_Option1: 'Use the dropdown menu to choose the phase of the sales process where you need assistance. If you’re unsure or your query spans multiple phases, opt for the "General" category.',
        Sidebar_Text2_Option2: 'Enter your query and let Sales Mentor provide the guidance you need.',
        Sidebar_Text3: 'You can browse through example questions for inspiration. Plus, if you need Sales Mentor to conduct online research, be sure to ask directly. For instance, say: "Find information online on the top-performing sales company in my country."',
        Sidebar_Text4: 'By following these steps, you can leverage the full potential of Sales Mentor to enhance your sales strategy.',
        Sidebar_Extended_Title1: 'Example prompts',
        Sidebar_Extended_PreselectedText_1: `
        How do I prepare for a sales visit to (Client) considering my (Product/Service) and (Context/Relationship)?<br><br>
        - <strong>Client</strong>: Describe your client. Include all relevant information. Example: “It is X, a company with 1,000 employees in the energy sector. They focus on renewables. I will specifically visit the HR Director, Gloria. We know she is analytical and has extensive experience in the training sector.”<br><br>
        - <strong>Product or Service</strong>: Describe your product or service. Example: “We offer consulting and negotiation training services.”<br><br>
        - <strong>Context or Relationship</strong>: Describe where you are in the sales cycle. Example: “We visited them a month ago, and they showed some interest, but nothing was confirmed during the previous visit.”`,
      
      Sidebar_Extended_PreselectedText_1_Short: 'How do I prepare for a sales visit to (Client) considering my (Product/Service) and (Context/Relationship)?',
    
      Sidebar_Extended_PreselectedText_2: `
        What questions can I ask my (Interlocutor) to uncover relevant information for the sale in this (Context)?<br><br>
        - <strong>Interlocutor</strong>: Describe your interlocutor. Example: “Oncologist specializing in Hodgkin's lymphoma. Very analytical profile. In my experience, they like to get straight to the point in conversations.”<br><br>
        - <strong>Context</strong>: Explain the context of the conversation. Example: “This is the second visit to the interlocutor. We have already presented our treatment before, but last time we didn’t get any conclusive feedback. The visit was cut short due to an emergency.”`,
      
      Sidebar_Extended_PreselectedText_2_Short: 'What questions can I ask my (Interlocutor) to uncover relevant information for the sale in this (Context)?',
    
      Sidebar_Extended_PreselectedText_3: `
        How can I present my (Proposal) to my (Interlocutor) to maximize its persuasive impact?<br><br>
        - <strong>Proposal</strong>: Describe your value proposition (or copy and paste the description or features of your product or service).<br><br>
        - <strong>Interlocutor</strong>: Note the characteristics and, most importantly, the needs you know or anticipate regarding your interlocutor.`,
      
      Sidebar_Extended_PreselectedText_3_Short: 'How can I present my (Proposal) to my (Interlocutor) to maximize its persuasive impact?',
    
      Sidebar_Extended_PreselectedText_4: `
        The client has raised an (Objection), how should I act to overcome it in this (Context)?<br><br>
        - <strong>Objection</strong>: Describe the objection. Example: “It seems expensive.”<br><br>
        - <strong>Context</strong>: Provide context for the conversation. Example: “I made a service proposal worth €5,000. The proposal consists of…”`,
      
      Sidebar_Extended_PreselectedText_4_Short: 'The client has raised an (Objection), how should I act to overcome it in this (Context)?',
    
      Sidebar_Extended_PreselectedText_5: `
        What closing technique could I use in this (Context)?<br><br>
        - <strong>Context</strong>: Describe the specific situation you are in. Example: “I made a proposal adjusting the price to their budget and reducing the scope of services, but they haven't replied yet, and several weeks have passed.”`,
      
      Sidebar_Extended_PreselectedText_5_Short: 'What closing technique could I use in this (Context)?',
        Sidebar_Button_Help: 'Help',
        Body_Section1_Title: 'Sales Mentor',
        Body_Section1_Subtitle: "Here are some examples of predefined questions/queries you can ask.",
        Body_Section1_SelectLabel: 'Please select a category',
        Body_Section1_VersionLabel: 'Mentor Version: ',
        Body_Section1_PreselectedText_1: 'How can I prepare my visit?',
        Body_Section1_PreselectedText_2: 'What can I do to overcome my customer\'s objections?',
        Body_Section1_PreselectedText_3: 'Can you give me tips on effective closing techniques?',
        Body_Section2_ProcessingText: 'Processing',
        Body_Section2_DeleteButtonText: 'Clear chat',
        Body_Section1_EditLabel: 'Edit questions',
        Body_Section1_ChooseLabel: 'Predefined questions',
        Categories_General: 'General',
        Categories_Prospeccion: 'Prospecting',
        Categories_Preparacion: 'Preparation',
        Categories_Apertura: 'Opening',
        Categories_Exploracion: 'Exploration',
        Categories_Propuesta: 'Proposal',
        Categories_SuperacionObjeciones: 'Overcoming objections',
        Categories_Cierre: 'Closing',
        Categories_Seguimiento: 'Follow-up',
        Categories_Transicion: 'Transition',
        Error_Default_Message: 'We are unable to process the response at this time. Please try again later',
        Error_AbortError: 'Response aborted by user.',
        Placeholder_MessageSend_Text: 'How can I help you?',
        Close_Button_Text: 'Close',
        Proposal_Message_Title: 'Input required to make a proposal',
        Proposal_Message: '1. Who the client company or entity is, what it does and its context. \n2. What are their difficulties, needs or objectives for which they would benefit from our product/service. (specify these needs, difficulties, or goals as to how they negatively affect the customer). \n3. Information about the interlocutor such as their position, what they are an expert in and the factors they have highlighted and that are important for the sale. Emotional aspects by which you can be attracted to sell. \n4. Where the sales process is and if there have been any previous meetings. Briefly describe what has been covered in each of them and what has been the result of them. \n5. Name, details, and descriptive information of the product or service being offered. \n6. The advantages of the product offered, if possible, depending on the client\'s difficulties and objectives. \n7. Cost information.',
        Proposal_Help_Button_Text: 'Examples for the \'Proposal\' category',
        Proposal_Message_Introduction_Html: 'This category has a special treatment since the objective is to generate a specific proposal by providing a context about a specific product or service.<br/>To do this, the user must enter the following information as indicated from the application. <br><br> It´s not mandatory to fill in all the fields. The only essential ones are (interlocutor´s needs, client, and description of our service, offered product). That being said, the more information you provide, the better the response will be.',
        Proposal_Message_Introduction_Html_2: 'This category has a special treatment since the objective is to generate a specific proposal by providing a context about a specific product or service. To do this, the user must enter the following information as indicated from the application It´s not mandatory to fill in all the fields. The only essential ones are (interlocutor´s needs, client, and description of our service, offered product). That being said, the more information you provide, the better the response will be.',
        Proposal_Message_Introduction_Html_3: 'I want you to help me generate a proposal',
        Proposal_Message_Html: '<ul><li>Who the client company or entity is, what it does and its context.</li><li>What are their difficulties, needs or objectives for which they would benefit from our product/service. (specify these needs, difficulties, or goals as to how they negatively affect the customer).</li><li>Information about the interlocutor such as their position, what they are an expert in and the factors they have highlighted and that are important for the sale. Emotional aspects by which you can be attracted to sell.</li><li>Where the sales process is and if there have been any previous meetings. Briefly describe what has been covered in each of them and what has been the result of them.</li><li>Name, details, and descriptive information of the product or service being offered.</li><li>The advantages of the product offered, if possible, depending on the client\'s difficulties and objectives.</li><li>Cost information.</li></ul>',
        Proposal_Message_Input_Example1_Title: 'Company Client and Context',
        Proposal_Message_Input_Example1_Text_Html: '<br/>The client company is XYZ Healthcare Solutions, a leading organization in the supply of medical products and services for hospitals and clinics. His focus is on providing comprehensive solutions to improve the quality of healthcare and patient experience in hospital settings',
        Proposal_Message_Input_Example2_Title: 'Difficulties and Client Needs: ',
        Proposal_Message_Input_Example2_Text_Html: '<br/><ul style="list-style-type:disc !important;"><li>XYZ Healthcare Solutions faces critical challenges in the efficient management of hospital resources, including extended wait times in waiting rooms and offices, negatively impacting patient satisfaction and the operational efficiency of medical staff.</li><li>They have trouble maintaining the availability and functionality of medical equipment in all areas of the hospital, which can lead to disruptions in medical care and increase risks to patients.</li><li>Lack of coordination between hospital departments results in poor communication and fragmented care for patients, which can lead to medical errors and an unsatisfactory care experience.</li></ul>',
        Proposal_Message_Input_Example3_Title: 'Caller Information',
        Proposal_Message_Input_Example3_Text_Html: '<br/>Our contact person at XYZ Healthcare Solutions is Mr. Carlos Garcia, Director of Hospital Operations. Carlos is an experienced leader in hospital management, with a deep understanding of the challenges and opportunities in healthcare. He has highlighted his concern for improving the hospital\'s operational efficiency and ensuring an exceptional healthcare experience for patients.',
        Proposal_Message_Input_Example4_Title: 'Status of the Sales Process',
        Proposal_Message_Input_Example4_Text_Html: '<br/>We have had several meetings with Mr. Garcia and the XYZ Healthcare Solutions team in the past. In these meetings, we discuss the critical challenges they face in hospital management and explore potential solutions to address these needs. Although they showed interest in our initial proposal, they are still evaluating other options in the market. However, they have expressed a willingness to continue discussions and are open to learning more details about how our solution can improve the efficiency and quality of healthcare at their hospital.',
        Proposal_Message_Input_Example5_Title: 'Product or Service Offered',
        Proposal_Message_Input_Example5_Text_Html: '<br/>Our company offers a Comprehensive Hospital Management Platform, specifically designed to address the unique challenges faced by hospitals like XYZ Healthcare Solutions in resource management and patient care. This platform includes modules to optimize waiting times, improve the maintenance and availability of medical equipment, and facilitate communication and coordination between hospital departments',
        Proposal_Message_Input_Example6_Title: 'Advantages of the Product Offered',
        Proposal_Message_Input_Example6_Text_Html: '<br/><ul style="list-style-type:disc !important;"><li>It significantly reduces waiting times in waiting rooms and doctors\' offices, improving patient satisfaction and operational efficiency for medical staff.</li><li>It improves the availability and functionality of medical equipment, reducing the risk of healthcare disruptions and improving patient safety.</li><li>It facilitates communication and coordination between hospital departments, ensuring integrated and error-free medical care for patients.</li><li>It provides monitoring and analysis tools to identify areas for improvement and optimize hospital processes.</li></ul>',
        Proposal_Message_Input_Example7_Title: 'Cost Information',
        Proposal_Message_Input_Example7_Text_Html: '<br/>The cost of our platform meets XYZ Healthcare Solutions\' needs and budget, with flexible and scalable licensing options. We are committed to delivering a significant return on investment through improving the operational efficiency and quality of healthcare in the hospital. ',
        LoginPage_User: 'User',
        LoginPage_Password: 'Password',
        LoginPage_Submit: 'Login',
        LoginPage_Unauthorized: 'The username or password is incorrect. Please try again.',
        SettingsModal_Title: 'Settings',
        SettingsModal_Save: 'Save',
        SettingsModal_Cancel: 'Cancel',
        SettingsModal_Close: 'Close modal',
        SettingsModal_ShowDocsReferences: 'Show answer documents references',
        SettingsModal_ContentLevel: 'Content level',
        SettingsModal_ContentLevel_Generic: 'Generic',
        SettingsModal_ContentLevel_Sector: 'Pharma',
        FailedPage_ErrorTitle: 'An error occurred',
        FailedPage_ErrorSubtitle: 'Sorry for the inconvenience',
        FailedPage_GoBack: 'Return',
        UserAvatar_AdminSettings: 'Admin settings',
        UserAvatar_Language: 'Language',
        Theme_Pref_Light: 'Light theme',
        Theme_Pref_Dark: 'Dark theme',
        UserAvatar_SignOut: 'Sign out',
        UnauthorizedPage_Title: 'Choose your monthly plan for greater flexibility, or if you\'re certain, don\'t miss out on the discount for the annual plan.',
        UnauthorizedPage_Subtitle: 'If you need multiple licenses for your team, contact us at info&#64;turingchallenge.com',
        UnauthorizedPage_SignOut: 'Sign Out',
        Modal_EditQuestions_Title: 'Edit questions',
        Modal_EditQuestions_Description: 'Here you can edit the predefined questions that will appear in the main section. Enter the new question and select the corresponding category.',
        Modal_EditQuestions_Tab_Question: 'Question',
        Modal_EditQuestions_Label_Question: 'Question',
        Modal_EditQuestions_Label_Category: 'Category',
        Modal_EditQuestions_Button_Cancel: 'Cancel',
        Modal_EditQuestions_Button_Accept: 'Accept'
    },
    'es': {
        App_Title: 'SAI Sales Mentor',
        Sidebar_Title1: 'Introducción',
        Sidebar_Text1: 'Sales Mentor: tu asistente personal para sobresalir en las ventas. Te acompañará en cada etapa del proceso de ventas, desde la prospección de clientes, pasando por la preparación de una visita, hasta la creación de propuestas persuasivas, el manejo de objeciones y el cierre.',

        Sidebar_Text1o: `
        ¿Cómo puedes usar Sales Mentor?<br><br>
        
        Resuelve cualquier duda sobre el proceso de venta más actual y efectivo. Pregunta en la pestaña General.<br><br>
        
        Herramientas clave para optimizar tus ventas: Si sabes a la fase de la venta a la que pertenece tu consulta, elige la pestaña y obtén una respuesta mucho más completa.<br><br>
        
        <ol>
          <li><strong>Prospección</strong>: descubre estrategias para identificar y captar clientes potenciales.</li><br>
          <li><strong>Apertura</strong>: Obtén ideas y guías sobre cómo iniciar las conversaciones dependiendo de si son a puerta fría, templada, o de seguimiento. Ya sean inbound o outbound.</li><br>
          <li><strong>Prepara visitas</strong>: Interactúa con Sales Mentor para obtener ideas, ejemplos y escenarios útiles sobre toda la información necesaria que deberías repasar y plantearte antes de iniciar una visita/llamada.</li><br>
          <li><strong>Prepara preguntas estratégicas que formular</strong>: Crea preguntas que generen reflexión y no levanten barreras, logrando información valiosa y despertando la necesidad de compra en el cliente.</li><br>
          <li><strong>Desarrolla propuestas persuasivas</strong>: Crea propuestas de valor impactantes. Luego, pide que las adapte en formato de email para un envío rápido.</li><br>
          <li><strong>Anticipa y supera objeciones</strong>: Prevén posibles objeciones y cómo abordarlas eficazmente.</li><br>
          <li><strong>Diseña estrategias de cierre</strong>: Prepárate con técnicas para lograr compromiso y cerrar la venta.</li><br>
          <li><strong>Planea y efectúa un buen seguimiento</strong>: Crea emails y estrategias para hacer un seguimiento adecuado a tus clientes, sin parecer pesado o desesperado.</li>
        </ol>
        `,
        Sidebar_Title2: 'Guía rápida',
        Sidebar_Text2: 'Sales Mentor ofrece una experiencia de usuario intuitiva y sencilla. Para empezar, siga estos dos sencillos pasos:',
        Sidebar_Text2_Option1: 'Use el menú desplegable para elegir la fase del proceso de ventas en la que necesita asistencia. Si no está seguro o su consulta abarca varias fases, elija la categoría "General".',
        Sidebar_Text2_Option2: 'Introduzca su consulta y Sales Mentor le proporcionará la orientación que necesita. ',
        Sidebar_Text3: 'Puede revisar algunas preguntas de ejemplo para inspirarse. Por otro lado, si necesita que Sales Mentor realice investigaciones en Internet, asegúrese de pedirlo directamente. Por ejemplo, diga: "Encuentra información en línea sobre la empresa de ventas líder en mi país".',
        Sidebar_Text4: 'Siguiendo estos pasos, puede aprovechar al máximo el potencial de Sales Mentor para mejorar su estrategia de ventas.',
        Sidebar_Extended_Title1: 'Ejemplos de preguntas',
            Sidebar_Extended_PreselectedText_1: `
              ¿Cómo me preparo para una visita de ventas a (Cliente) considerando mi (Producto /Servicio) y (Contexto/Relación)?<br><br><hr style="padding-bottom:2vh;">
              - <strong>Cliente</strong>: Describa a su cliente. Incluya toda la información que crea relevante. Ejemplo: “Se trata de X, una empresa de 1000 trabajadores, en el sector de la Energía. Se dedica a las renovables. En particular, voy a visitar a la directora de RRHH, que se llama Gloria. Sabemos que es una persona analítica y con mucha experiencia en el sector de la formación.”<br><br>
              - <strong>Producto o Servicio</strong>: Describa su producto o servicio. Ejemplo: “Ofrecemos servicios de consultoría y entrenamiento en negociación.”<br><br>
              - <strong>Contexto o Relación</strong>: Describa en qué punto del ciclo de ventas se encuentra. Ejemplo: “Le visitamos hace un mes, y mostró algo de interés, pero no se concretó nada en la anterior visita.”`,
            
            Sidebar_Extended_PreselectedText_1_Short: '¿Cómo me preparo para una visita de ventas a (Cliente) considerando mi (Producto /Servicio) y (Contexto/Relación)?',
          
            Sidebar_Extended_PreselectedText_2: `
              ¿Qué preguntas puedo hacer a mi (Interlocutor) para descubrir información relevante de cara a la venta en este (Contexto)?<br><br><hr style="padding-bottom:2vh;">
              - <strong>Interlocutor</strong>: Describa a su interlocutor. Ejemplo: “Oncólogo especialista en linfoma de Hopkins. Perfil muy analítico. En mi experiencia, le gusta ir al grano en las conversaciones.”<br><br>
              - <strong>Contexto</strong>: Explique el contexto en el que se da la conversación. Ejemplo: “Se trata de la segunda visita al interlocutor. Ya le hemos presentado previamente nuestro tratamiento y la vez pasada no nos dio un feedback concluyente. Se limitó a atender y tuvimos que cortar la visita porque tuvo que salir a atender una urgencia.”`,
            
            Sidebar_Extended_PreselectedText_2_Short: '¿Qué preguntas puedo hacer a mi (Interlocutor) para descubrir información relevante de cara a la venta en este (Contexto?',
          
            Sidebar_Extended_PreselectedText_3: `
              ¿Cómo puedo trasladar mi (Propuesta) a mi (Interlocutor) para maximizar el impacto persuasivo de la misma?<br><br><hr style="padding-bottom:2vh;">
              - <strong>Propuesta</strong>: Describa su propuesta de valor (o copie y pegue la descripción o características de su producto o servicio).<br><br>
              - <strong>Interlocutor</strong>: Señale las características y, sobre todo, las necesidades que conozca o intuya sobre su interlocutor.`,
            
            Sidebar_Extended_PreselectedText_3_Short: '¿Cómo puedo trasladar mi (Propuesta) a mi (Interlocutor) para maximizar el impacto persuasivo de la mism?',
          
            Sidebar_Extended_PreselectedText_4: `
              El cliente me ha puesto una (Objeción), ¿cómo debo actuar para superarla en este (Contexto)?<br><br><hr style="padding-bottom:2vh;">
              - <strong>Objeción</strong>: Describa la objeción. Ejemplo: “Me parece caro.”<br><br>
              - <strong>Contexto</strong>: Ofrezca contexto a la conversación. Ejemplo: “Le he hecho una propuesta de servicios por valor de 5.000 €. La propuesta consiste en…”`,
            
            Sidebar_Extended_PreselectedText_4_Short: 'El cliente me ha puesto una (Objeción), ¿cómo debo actuar para superarla en este (Contexto?',
          
            Sidebar_Extended_PreselectedText_5: `
              ¿Qué técnica de cierre podría utilizar en este (Contexto)?<br><br><hr style="padding-bottom:2vh;">
              - <strong>Contexto</strong>: Describa la situación particular en la que se encuentra. Ejemplo: “Le he hecho una propuesta ajustando el precio a su presupuesto y reduciendo el alcance de los servicios, pero no me ha contestado todavía y han pasado varias semanas.”`,
            
            Sidebar_Extended_PreselectedText_5_Short: '¿Qué técnica de cierre podría utilizar en este (Context)'
        ,          
        Sidebar_Button_Help: 'Ayuda',
        Body_Section1_Title: 'Sales Mentor',
        Body_Section1_Subtitle: "Aquí tienes unos ejemplos de preguntas/consultas predefinidas que puedes realizar.",
        Body_Section1_SelectLabel: 'Selecciona categoría',
        Body_Section1_VersionLabel: 'Mentor Version: ',
        Body_Section1_PreselectedText_1: '¿Cómo puedo preparar mi visita?',
        Body_Section1_PreselectedText_2: '¿Qué puedo hacer para superar las objeciones de mi cliente?',
        Body_Section1_PreselectedText_3: '¿Puedes darme consejos sobre técnicas de cierre efectivas?',
        Body_Section2_ProcessingText: 'Procesando',
        Body_Section2_DeleteButtonText: 'Limpiar chat',
        Body_Section1_EditLabel: 'Editar preguntas',
        Body_Section1_ChooseLabel: 'Preguntas predefinidas',
        Categories_General: 'General',
        Categories_Prospeccion: 'Prospección',
        Categories_Preparacion: 'Preparación',
        Categories_Apertura: 'Apertura',
        Categories_Exploracion: 'Exploración',
        Categories_Propuesta: 'Propuesta',
        Categories_SuperacionObjeciones: 'Superación de objeciones',
        Categories_Cierre: 'Cierre',
        Categories_Seguimiento: 'Seguimiento',
        Categories_Transicion: 'Transición',
        Error_Default_Message: 'En estos momentos no podemos procesar la respuesta. Por favor, inténtalo más tarde',
        Error_AbortError: 'Respuesta abortada por el usuario.',
        Placeholder_MessageSend_Text: '¿En qué puedo ayudarte?',
        Close_Button_Text: 'Cerrar',
        Proposal_Message_Title: 'Entrada necesaria para realizar una propuesta',
        Proposal_Message: '1. Quien es la empresa o entidad cliente, a que se dedica y su contexto. \n2. Cuáles son sus dificultades, necesidades u objetivos por los cuales se beneficiarían de nuestro producto/servicio. (especificar estas necesidades, dificultades u objetivos en cuanto a cómo afectan negativamente al cliente). \n3. Información particular del interlocutor como su posición, en que es experto y los factores que haya resaltado y sean de importancia para la venta. Aspectos emocionales por los cuales se le pueda atraer para la venta. \n4. En qué punto está el proceso de venta y si se han tenido reuniones anteriores. Describir brevemente que se ha tratado en cada una de ellas y cuál ha sido el resultado de estas. \n5. Nombre, detalles e información descriptiva del producto o servicio que se ofrece. \n6. Ventajas del producto ofrecido, a ser posible que vayan en función de las dificultades y objetivos del cliente. \n7. Información de coste.',
        Proposal_Help_Button_Text: 'Ejemplos para la categoría \'Propuesta\'',
        Proposal_Message_Introduction_Html: 'Esta categoría tiene un tratamiento especial ya que el objetivo es generar una propuesta especifica proporcionando un contexto sobre un producto o servicio concreto.<br/>Para ello, el usuario deberá introducir la siguiente información tal y como se le indica desde la aplicación.<br><br>No es obligatorio rellenar todos los campos. Los únicos esenciales son (necesidades del interlocutor, cliente y descripción de nuestro servicio, producto ofrecido). Dicho esto, cuanta más información des mejor será la respuesta.',
        Proposal_Message_Introduction_Html_2: 'Esta categoría tiene un tratamiento especial ya que el objetivo es generar una propuesta especifica proporcionando un contexto sobre un producto o servicio concreto. Para ello, el usuario deberá introducir la siguiente información tal y como se le indica desde la aplicación. No es obligatorio rellenar todos los campos. Los únicos esenciales son (necesidades del interlocutor, cliente y descripción de nuestro servicio, producto ofrecido). Dicho esto, cuanta más información des mejor será la respuesta. ',
        Proposal_Message_Introduction_Html_3: 'Quiero que me ayudes a generar una propuesta',
        Proposal_Message_Html: '<ul><li>Quien es la empresa o entidad cliente, a que se dedica y su contexto.</li><li>Cuáles son sus dificultades, necesidades u objetivos por los cuales se beneficiarían de nuestro producto/servicio. (especificar estas necesidades, dificultades u objetivos en cuanto a cómo afectan negativamente al cliente).</li><li>Información particular del interlocutor como su posición, en que es experto y los factores que haya resaltado y sean de importancia para la venta. Aspectos emocionales por los cuales se le pueda atraer para la venta.</li><li> En qué punto está el proceso de venta y si se han tenido reuniones anteriores. Describir brevemente que se ha tratado en cada una de ellas y cuál ha sido el resultado de estas.</li><li>Nombre, detalles e información descriptiva del producto o servicio que se ofrece.</li><li>Ventajas del producto ofrecido, a ser posible que vayan en función de las dificultades y objetivos del cliente.</li><li>Información de coste.</li></ul>',
        Proposal_Message_Input_Example_Title: 'Ejemplo de entrada',
        Proposal_Message_Input_Example1_Title: 'Empresa Cliente y Contexto',
        Proposal_Message_Input_Example1_Text_Html: '<br/>La empresa cliente es XYZ Healthcare Solutions, una organización líder en el suministro de servicios y productos médicos para hospitales y clínicas. Su enfoque se centra en proporcionar soluciones integrales para mejorar la calidad de la atención médica y la experiencia del paciente en entornos hospitalarios.',
        Proposal_Message_Input_Example2_Title: 'Dificultades y Necesidades del Cliente',
        Proposal_Message_Input_Example2_Text_Html: '<br/><ul style="list-style-type:disc !important;"><li>XYZ Healthcare Solutions enfrenta desafíos críticos en la gestión eficiente de los recursos hospitalarios, incluidos los tiempos de espera prolongados en salas de espera y consultorios, lo que afecta negativamente la satisfacción del paciente y la eficiencia operativa del personal médico.</li><li>Experimentan dificultades para mantener la disponibilidad y funcionalidad del equipamiento médico en todas las áreas del hospital, lo que puede generar interrupciones en la atención médica y aumentar los riesgos para los pacientes. </li><li>La falta de coordinación entre los departamentos hospitalarios resulta en una comunicación deficiente y una atención fragmentada para los pacientes, lo que puede conducir a errores médicos y una experiencia de atención insatisfactoria.</li></ul> ',
        Proposal_Message_Input_Example3_Title: 'Información del Interlocutor',
        Proposal_Message_Input_Example3_Text_Html: '<br/>Nuestro interlocutor en XYZ Healthcare Solutions es el Sr. Carlos García, Director de Operaciones Hospitalarias. Carlos es un líder experimentado en la gestión de hospitales, con un profundo conocimiento de los desafíos y oportunidades en la atención médica. Ha destacado su preocupación por mejorar la eficiencia operativa del hospital y garantizar una experiencia de atención médica excepcional para los pacientes.',
        Proposal_Message_Input_Example4_Title: 'Estado del Proceso de Venta',
        Proposal_Message_Input_Example4_Text_Html: '<br/>Hemos tenido varias reuniones con el Sr. García y el equipo de XYZ Healthcare Solutions en el pasado. En estas reuniones, discutimos los desafíos críticos que enfrentan en la gestión hospitalaria y exploramos posibles soluciones para abordar estas necesidades. Aunque mostraron interés en nuestra propuesta inicial, aún están evaluando otras opciones en el mercado. Sin embargo, han expresado su disposición a continuar las conversaciones y están abiertos a conocer más detalles sobre cómo nuestra solución puede mejorar la eficiencia y la calidad de la atención médica en su hospital.',
        Proposal_Message_Input_Example5_Title: 'Producto o Servicio Ofrecido',
        Proposal_Message_Input_Example5_Text_Html: '<br/>Nuestra empresa ofrece una Plataforma Integral de Gestión Hospitalaria, diseñada específicamente para abordar los desafíos únicos que enfrentan hospitales como XYZ Healthcare Solutions en la gestión de recursos y la atención al paciente. Esta plataforma incluye módulos para optimizar los tiempos de espera, mejorar el mantenimiento y la disponibilidad del equipamiento médico, y facilitar la comunicación y coordinación entre los departamentos hospitalarios.',
        Proposal_Message_Input_Example6_Title: 'Ventajas del Producto Ofrecido',
        Proposal_Message_Input_Example6_Text_Html: '<br/><ul style="list-style-type:disc !important;"><li>Reduce significativamente los tiempos de espera en salas de espera y consultorios, mejorando la satisfacción del paciente y la eficiencia operativa del personal médico.</li><li>Mejora la disponibilidad y funcionalidad del equipamiento médico, reduciendo el riesgo de interrupciones en la atención médica y mejorando la seguridad del paciente.</li><li>Facilita la comunicación y coordinación entre los departamentos hospitalarios, garantizando una atención médica integrada y sin errores para los pacientes. </li><li>Proporciona herramientas de seguimiento y análisis para identificar áreas de mejora y optimizar los procesos hospitalarios. </li></ul>',
        Proposal_Message_Input_Example7_Title: 'Información de Coste',
        Proposal_Message_Input_Example7_Text_Html: '<br/>El costo de nuestra plataforma se adapta a las necesidades y al presupuesto de XYZ Healthcare Solutions, con opciones de licenciamiento flexibles y escalables. Estamos comprometidos a ofrecer un retorno de la inversión significativo a través de la mejora de la eficiencia operativa y la calidad de la atención médica en el hospital. ',
        LoginPage_User: 'Usuario',
        LoginPage_Password: 'Contraseña',
        LoginPage_Submit: 'Login',
        LoginPage_Unauthorized: 'El usuario o la contraseña son inválidos. Por favor, vuelve a intentarlo.',
        SettingsModal_Title: 'Configuración',
        SettingsModal_Save: 'Guardar',
        SettingsModal_Cancel: 'Cancelar',
        SettingsModal_Close: 'Cerrar modal',
        SettingsModal_ShowDocsReferences: 'Mostrar referencias de documentos de respuesta',
        SettingsModal_ContentLevel: 'Nivel de contenido',
        SettingsModal_ContentLevel_Generic: 'Genérico',
        SettingsModal_ContentLevel_Sector: 'Farma',
        FailedPage_ErrorTitle: 'Ocurrió un error',
        FailedPage_ErrorSubtitle: 'Disculpe las molestias',
        FailedPage_GoBack: 'Volver',
        UserAvatar_AdminSettings: 'Configuración de administrador',
        UserAvatar_Language: 'Idioma',
        Theme_Pref_Light: 'Tema claro',
        Theme_Pref_Dark: 'Tema oscuro',
        UserAvatar_SignOut: 'Cerrar sesión',
        UnauthorizedPage_Title: 'Elije tu plan mensual si quieres mayor flexibilidad en su uso, o si lo tienes claro, no dejes escapar el descuento del plan anual.',
        UnauthorizedPage_Subtitle: 'Si quieres varias licencias para tu equipo ponte en contacto con nosotros en info&#64;turingchallenge.com',
        UnauthorizedPage_SignOut: 'Cerrar sesión',
        Modal_EditQuestions_Title: 'Editar preguntas',
        Modal_EditQuestions_Description: 'Aquí puedes editar las preguntas predefinidas que aparecerán en la sección principal. Ingresa la nueva pregunta y selecciona la categoría correspondiente.',
        Modal_EditQuestions_Tab_Question: 'Pregunta',
        Modal_EditQuestions_Label_Question: 'Pregunta',
        Modal_EditQuestions_Label_Category: 'Categoría',
        Modal_EditQuestions_Button_Cancel: 'Cancelar',
        Modal_EditQuestions_Button_Accept: 'Aceptar'
    }
};

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem("lang") || "es",
    globalInjection: true,
    messages
});

export default i18n;
