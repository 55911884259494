<template>
  <!-- Sidebar cerrado en dispositivos pequeños -->
  <aside v-if="!props.asideOpen"
         class="transition-colors duration-300 fixed top-[64px] left-0 h-[calc(100%-64px)] w-16 z-50 flex flex-col items-center transition-all duration-300 ease-in-out lg:hidden">
    <button type="button" @click="toggleSidebar"
            class="absolute top-2 left-2 flex items-center justify-center w-6 h-6 rounded-full bg-white dark:bg-darkBackgroundContainer text-customPurple hover:text-customPurple hover:bg-gray-200 dark:hover:bg-gray-600 border border-customPurple ">
      <img src="@/assets/icons/menu-icon.svg" alt="Menu Icon" class="w-5 h-5" />
    </button>
  </aside>

  <!-- Sidebar cerrado en pantallas grandes -->
  <aside v-if="!props.asideOpen"
         class="transition-colors duration-300 fixed top-[64px] left-0 h-[calc(100%-64px)] w-16 bg-lightBackgroundContainer dark:bg-darkBackgroundContainer text-lightText dark:text-darkText p-2 z-50 flex flex-col items-center transition-all duration-300 ease-in-out hidden lg:flex">
    <button type="button" @click="toggleSidebar"
            class="absolute top-2 left-14 flex items-center justify-center w-5 h-5 rounded-full bg-white dark:bg-darkBackgroundContainer text-customPurple hover:text-customPurple hover:bg-gray-200 dark:hover:bg-gray-600 border border-customPurple ">
      <img src="@/assets/icons/menu-icon.svg" alt="Menu Icon" class="w-5 h-5" />
    </button>
    <img 
      src="@/assets/icons/home.svg"
      :alt="$t('Sidebar_Title1')"
      class="w-8 h-8 mb-6 cursor-pointer text-customPurple hover:text-customPurple "
      @click="openSection('intro')" 
    />
    <img src="@/assets/icons/hamburguer.svg" :alt="$t('Sidebar_Title2')" class="w-8 h-8 mb-6 cursor-pointer text-customPurple hover:text-customPurple " @click="openSection('guide')" />
    <img src="@/assets/icons/book.svg" :alt="$t('Sidebar_Extended_Title1')" class="w-8 h-8 mb-6 cursor-pointer text-customPurple hover:text-customPurple " @click="openSection('examples')" />
  </aside>

  <!-- Sidebar abierto -->
  <aside v-if="props.asideOpen"
         :class="[
           'transition-colors duration-300 fixed top-[64px] left-0 h-[calc(100%-64px)] p-6 z-50 transition-transform duration-300 ease-in-out flex flex-col',
           examplesExpanded ? 'w-[600px]' : 'w-[400px]',
           'bg-lightBackgroundContainer dark:bg-darkBackgroundContainer text-lightText dark:text-darkText'
         ]">
    
    <div class="flex justify-end mb-6">
      <button type="button" @click="toggleSidebar"
              class="flex items-center justify-center w-8 h-8 rounded-full bg-white dark:bg-darkBackground text-customPurple hover:text-customPurple hover:bg-gray-200 dark:hover:bg-gray-600  mb-3">
        <img src="@/assets/icons/menu-close-icon.svg" alt="Close Menu Icon" class="w-4 h-4" />
      </button>
    </div>

    <div class="flex flex-col space-y-6 flex-grow overflow-y-auto">
      <div>
        <div class="flex justify-between items-center font-bold text-xl cursor-pointer text-lightText dark:text-darkText" @click="toggleSection('intro')">
          <div class="flex items-center">
            <img 
              src="@/assets/icons/home.svg"
              :alt="$t('Sidebar_Title1')" 
              class="w-8 h-8 mr-5 text-customPurple hover:text-customPurple " 
            />
            {{ $t('Sidebar_Title1') }}
          </div>
          <img :src="sectionOpen === 'intro' ? require('@/assets/icons/arrow-up.svg') : require('@/assets/icons/arrow-down.svg')" 
               alt="Toggle Arrow" 
               class="w-6 h-6 text-customPurple hover:text-customPurple " />
        </div>
        <div v-if="sectionOpen === 'intro'" class="mt-3 text-base text-lightText dark:text-darkText leading-[24px] font-normal">
          <span v-html="$t('Sidebar_Text1')"></span><br><br>
          <span v-html="$t('Sidebar_Text1o')"></span>
        </div>
      </div>

      <div>
        <div class="flex justify-between items-center font-bold text-xl cursor-pointer text-lightText dark:text-darkText" @click="toggleSection('guide')">
          <div class="flex items-center">
            <img src="@/assets/icons/hamburguer.svg" :alt="$t('Sidebar_Title2')" class="w-8 h-8 mr-5 text-customPurple hover:text-customPurple " />
            {{ $t('Sidebar_Title2') }}
          </div>
          <img :src="sectionOpen === 'guide' ? require('@/assets/icons/arrow-up.svg') : require('@/assets/icons/arrow-down.svg')" 
               alt="Toggle Arrow" 
               class="w-6 h-6 text-customPurple hover:text-customPurple " />
        </div>
        <div v-if="sectionOpen === 'guide'" class="mt-3 text-base text-lightText dark:text-darkText leading-[24px] font-normal flex-grow">
          {{ $t('Sidebar_Text2') }}
          <ol class="mt-3 list-decimal list-inside">
            <li>{{ $t('Sidebar_Text2_Option1') }}</li>
            <li>{{ $t('Sidebar_Text2_Option2') }}</li>
          </ol>
        </div>
      </div>

      <div>
        <div class="flex justify-between items-center font-bold text-xl cursor-pointer text-lightText dark:text-darkText" @click="toggleSection('examples')">
          <div class="flex items-center">
            <img src="@/assets/icons/book.svg" :alt="$t('Sidebar_Extended_Title1')" class="w-8 h-8 mr-5 text-customPurple hover:text-customPurple " />
            {{ $t('Sidebar_Extended_Title1') }}
          </div>
          <img :src="sectionOpen === 'examples' ? require('@/assets/icons/arrow-up.svg') : require('@/assets/icons/arrow-down.svg')" 
               alt="Toggle Arrow" 
               class="w-6 h-6 text-customPurple hover:text-customPurple " />
        </div>
        <div v-if="sectionOpen === 'examples'" class="mt-3 text-base text-lightText dark:text-darkText leading-[24px] font-normal flex-grow">
          <div v-for="(example, index) in examples" :key="index" class="mb-4">
    <button
      class="w-full text-left hover:text-customPurple dark:hover:text-customPurple p-4 border rounded-lg border-gray-300 dark:border-gray-600 bg-lightBackgroundContainer dark:bg-darkBackgroundContainer "
      @click="toggleExample(index)"
    >
      <span v-html="example.expanded ? $t(example.longText) : $t(example.shortText)"></span>

      <img 
        v-if="!example.expanded" 
        src="@/assets/icons/expand-icon.svg" 
        alt="Expand Icon" 
        class="float-right w-6 h-6 text-customPurple dark:text-customPurple hover:text-customPurple dark:hover:text-customPurple " 
      />

      <img 
        v-else 
        :src="isDarkMode ? require('@/assets/icons/send-icon-dark.svg') : require('@/assets/icons/send-icon-light.svg')" 
        alt="Send Icon" 
        class="float-right w-6 h-6 text-customPurple dark:text-customPurple hover:text-customPurple dark:hover:text-customPurple " 
        @click="sendSelectedText($t(example.shortText))"
      />
    </button>
  </div>
          <div class="mt-6">
            <button @click="closeModalAndToggle" class="w-full text-left hover:text-customPurple dark:hover:text-customPurple">
              {{ $t('Proposal_Help_Button_Text') }}
              <img 
                src="@/assets/icons/proposal-icon.svg" 
                alt="Proposal Icon" 
                class="float-right w-6 h-6 text-customPurple dark:text-customPurple hover:text-customPurple dark:hover:text-customPurple "
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';

const props = defineProps({
  asideOpen: Boolean,
  theme: {
    type: String,
    default: 'light'
  }
});

const emit = defineEmits(['closeModal', 'toggleSidebar', 'proposeText']);
const sectionOpen = ref(null);
const examplesExpanded = ref(false);
const isDarkMode = ref(false);

const examples = ref([
  { shortText: 'Sidebar_Extended_PreselectedText_1_Short', longText: 'Sidebar_Extended_PreselectedText_1', expanded: false },
  { shortText: 'Sidebar_Extended_PreselectedText_2_Short', longText: 'Sidebar_Extended_PreselectedText_2', expanded: false },
  { shortText: 'Sidebar_Extended_PreselectedText_3_Short', longText: 'Sidebar_Extended_PreselectedText_3', expanded: false },
  { shortText: 'Sidebar_Extended_PreselectedText_4_Short', longText: 'Sidebar_Extended_PreselectedText_4', expanded: false },
]);

const openSection = (section) => {
  sectionOpen.value = section;
  emit('toggleSidebar');
};

onMounted(() => {
  isDarkMode.value = document.documentElement.classList.contains('dark');
  const observer = new MutationObserver(() => {
    isDarkMode.value = document.documentElement.classList.contains('dark');
  });
  observer.observe(document.documentElement, {
    attributes: true,
    attributeFilter: ['class'],
  });
});

const toggleSection = (section) => {
  if (sectionOpen.value === section) {
    sectionOpen.value = null;
  } else {
    sectionOpen.value = section;
  }
};

const toggleSidebar = () => {
  emit('toggleSidebar');
};

const sendSelectedText = (text) => {
  if (text) {
    emit('update:userText', text);
    emit('toggleSidebar');
  }
};

const toggleExample = (index) => {
  examples.value[index].expanded = !examples.value[index].expanded;
};

const closeModalAndToggle = () => {
  emit('closeModal');
  emit('toggleSidebar');
  emit('proposeText');
};
</script>

<style scoped>
ol {
  list-style-type: decimal;
  padding-left: 20px;
}
</style>
