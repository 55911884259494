<template>
  <header class="fixed top-0 left-0 w-full h-16 bg-lightBackground dark:bg-darkBackgroundContainer shadow-md z-50 transition-colors duration-300">
    <div class="flex justify-between items-center p-4">
      <div class="flex items-center mb-6 justify-start">
        <LogoContainer />
      </div>
      <div class="flex items-center mb-6 justify-end">
        <UserAvatar @onChangedLanguage="forceRerender" />
      </div>
    </div>
  </header>
</template>

<script setup>
import { defineEmits } from 'vue';
import UserAvatar from '../ui/UserAvatar.vue';
import LogoContainer from './LogoContainer.vue';

const emit = defineEmits(['forceRerender', 'onShowSettings']);

const forceRerender = () => {
  emit('forceRerender');
};

const onShowSettings = () => {
  emit('onShowSettings');
};
</script>
