<template>
  <textarea 
    :value="modelValue"
    id="chat"
    ref="textarea"
    rows="1"
    maxlength="7000"
    class="block p-5 w-full text-sm rounded-lg pr-12 resize-none bg-lightBackgroundContainer dark:bg-darkBorder text-lightText dark:text-darkText placeholder-gray-400 dark:placeholder-gray-500 min-h-[45px] max-h-[150px] border-none overflow-y-auto shadow-lg transition-colors duration-300"
    :placeholder="$t('Placeholder_MessageSend_Text')" 
    @input="onInput"
    @keydown="handleKeyDown"
  ></textarea>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  isResponding: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'send']);

const onInput = (event) => {
  const value = event.target.value;
  if (typeof value === 'string') {
    emit('update:modelValue', value);
  } else {
    console.error("El valor ingresado no es un string:", value);
  }
};

const handleKeyDown = (event) => {
  if (props.isResponding) return;

  if (event.key === 'Enter' && !event.shiftKey && !event.altKey) {
    event.preventDefault();
    if (typeof props.modelValue === 'string' && props.modelValue.trim()) {
      emit('send', props.modelValue.trim());
    }
  }
};
</script>
