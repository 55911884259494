import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

/**
 * Configuration object for MSAL (Microsoft Authentication Library).
 * @type {Object}
 */
export const msalConfig = {
  auth: {
    clientId: '6a27b3b1-8f0e-4200-b46e-86a32158b136', // Asegúrate de que sea el Client ID correcto de tu aplicación registrada
    authority: 'https://turingsaas.ciamlogin.com/', // La autoridad debe estar correctamente configurada
    redirectUri: '/', // Debe coincidir con la URI de redirección registrada en Azure AD
    postLogoutRedirectUri: '/' // URI a la que el usuario será redirigido después de cerrar sesión
  },
  cache: {
    cacheLocation: 'localStorage', // La caché se almacenará en localStorage para persistir entre sesiones
    // storeAuthStateInCookie: false // Si tienes problemas de cookies en navegadores, considera habilitar esto
  },
  system: {
    loggerOptions: {
      /**
       * Callback function for logging library output.
       * @param {LogLevel} level - Level of log based on LogLevel
       * @param {string} message - Message to log
       * @param {boolean} containsPii - Flag indicating if the message contains PII
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Warning // Puedes cambiar el nivel de log para depuración, p. ej., LogLevel.Verbose para más detalles
    }
  }
};

/**
 * MSAL instance initialized with configurations.
 * @type {PublicClientApplication}
 */
export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Scopes for ID token to be used at Microsoft Identity Platform endpoints.
 * @type {Object}
 */
export const loginRequest = {
  scopes: [
    "api://3218768d-ae60-4fa5-a36a-9b6c7850ce24/API.Read" // Asegúrate de que este scope esté configurado en tu API en Azure AD
  ]
};

/**
 * Configuration for Microsoft Graph API endpoints.
 * @type {Object}
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me', // Endpoint para obtener información del usuario autenticado
  graphProfilePhoto: 'https://graph.microsoft.com/v1.0/me/photo/$value' // Endpoint para obtener la foto de perfil del usuario
};
