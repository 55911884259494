import { NavigationClient } from "@azure/msal-browser";

export class CustomNavigationClient extends NavigationClient {
    /**
     * Crea una instancia del cliente de navegación personalizado.
     * @param {import("vue-router").Router} router - El router de Vue-router utilizado para la navegación en la aplicación.
     */
    constructor(router) {
        super();
        this.router = router;
    }

    /**
     * Navega a otras páginas dentro de la misma aplicación web.
     * Puedes usar el hook useHistory proporcionado por react-router-dom para aprovechar el enrutamiento del lado del cliente.
     * @param {string} url - La URL a la que navegar.
     * @param {import("@azure/msal-browser").NavigationOptions} options - Opciones de navegación proporcionadas por MSAL.
     * @returns {Promise<boolean>} Retorna una promesa que resuelve a `false` indicando que no se ha utilizado la navegación estándar del navegador.
     */
    async navigateInternal(url, options) {
        const relativePath = url.replace(window.location.origin, '');
        if (options.noHistory) {
            this.router.replace(relativePath);
        } else {
            this.router.push(relativePath);
        }

        return false;
    }
}
