<template>
  <div class="relative flex items-center w-1/2 px-4" ref="dropdownRef">
    <button 
      @click="toggleDropdown"
      class="flex items-center text-lightText dark:text-darkText cursor-pointer focus:outline-none"
    >
      <img 
        v-if="!isDarkMode" 
        src="@/assets/icons/light-list.svg" 
        alt="Light List Icon"
        class="inline-block w-5 h-5 mr-2"
      />
      <img 
        v-else 
        src="@/assets/icons/dark-list.svg" 
        alt="Dark List Icon"
        class="inline-block w-5 h-5 mr-2"
      />
      
      <span>{{ t("Body_Section1_SelectLabel") }}{{ selectedCategory ? `: ${t(selectedCategory.text)}` : '' }}</span> 
    </button>
    <ul 
      v-if="dropdownOpen && reactiveCategories && reactiveCategories.length > 0"
      :class="[dropdownDirection === 'down' ? 'top-full mt-2' : 'bottom-full mb-2', 
      'absolute w-full bg-lightBackgroundContainer dark:bg-darkBackgroundContainer text-lightText dark:text-darkText rounded-sm shadow-lg z-10 max-h-60 border-lightBorder dark:border-darkBorder custom-scrollbar grid grid-cols-1 sm:grid-cols-2 gap-2']"
      ref="dropdownEl"
    >
      <li 
        v-for="option in reactiveCategories" 
        :key="option.id"
        @click="selectCategory(option)"
        class="cursor-pointer px-4 py-2 hover:text-customPurple"
        :class="{'text-customPurple': selectedCategory && selectedCategory.id === option.id}"
      >
        {{ t(option.text) }} 
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const props = defineProps({
  categories: Array,         
  selectedCategory: Object,  
});

const emit = defineEmits(['update:category']); 

const dropdownOpen = ref(false);
const isDarkMode = ref(false);
const dropdownDirection = ref('up');
const dropdownEl = ref(null);
const dropdownRef = ref(null);

const defaultCategory = { id: 'general', text: 'Categories_General' };
const selectedCategory = ref(props.selectedCategory || defaultCategory);
const reactiveCategories = ref([...props.categories]);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
  nextTick(() => {
    if (dropdownEl.value) {
      const dropdownRect = dropdownEl.value.getBoundingClientRect();
      const spaceBelow = window.innerHeight - dropdownRect.bottom;
      const spaceAbove = dropdownRect.top;

      dropdownDirection.value = (spaceAbove < 150 && spaceBelow > 150) ? 'down' : 'up';
    }
  });
};

const selectCategory = (option) => {
  const translatedOption = { ...option, text: t(option.text) };
  selectedCategory.value = translatedOption; 
  emit('update:category', translatedOption); 
};

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

watch(locale, () => {
  selectedCategory.value = defaultCategory;  
  emit('update:category', defaultCategory);  
});

watch(() => props.selectedCategory, (newCategory) => {
  selectedCategory.value = newCategory || defaultCategory;
});

watch(() => props.categories, (newCategories) => {
  reactiveCategories.value = newCategories.map(category => ({
    ...category,
    text: category.text 
  }));
});

onMounted(() => {
  emit('update:category', selectedCategory.value);
  
  const updateTheme = () => {
    isDarkMode.value = document.documentElement.classList.contains('dark');
  };

  const observer = new MutationObserver(() => updateTheme());
  observer.observe(document.documentElement, {
    attributes: true,
    attributeFilter: ['class'],
  });
  updateTheme();

  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.custom-scrollbar {
  overflow-y: auto;
}
</style>