<template>
    <main class="min-h-screen w-full">
        <div id="content-wrap">
            <header>
                <div class="items-center space-x-1">
                    <div class="grid grid-cols-1">
                        <div style="float:right;text-align: right;padding:15px;">
                            <span
                                v-bind:class="{ 'selected-language': $i18n.locale == 'es', 'not-selected-language': $i18n.locale != 'es' }"
                                @click="$i18n.locale = 'es'">ES</span>&nbsp;
                            <span
                                v-bind:class="{ 'selected-language': $i18n.locale == 'en', 'not-selected-language': $i18n.locale != 'en' }"
                                @click="$i18n.locale = 'en'">EN</span>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 md:grid-cols-4 divide-x container-logos">
                    <div class="hidden md:block"></div>
                    <div class="center-cell col-span-2">
                        <img alt="Vue logo" src="../../assets/images/logo.png" class="logo-header" style="float: left;">
                        <img alt="Vue logo" src="../../assets/images/logo-turing.png" class="logo-header"
                            style="float: right;">
                    </div>
                    <div class="hidden md:block">

                    </div>
                </div>
            </header>

            <div class="grid grid-cols-2 md:grid-cols-4 divide-x section-1 ">
                <div class="hidden md:block"></div>
                <div class="col-span-2">
                    <div class="section1-title">{{ $t("Body_Section1_Title") }}</div>
                </div>
            </div>

            <section class="bg-white dark:bg-gray-900">
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div class="mx-auto max-w-screen-sm text-center">
                        <!-- <h1
                            class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
                            500</h1> -->
                        <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                            {{ $t("FailedPage_ErrorTitle") }}</p>
                        <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">{{ $t("FailedPage_ErrorSubtitle")
                            }}</p>
                        <button @click="goBack()"
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-52 px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {{ $t("FailedPage_GoBack") }}
                        </button>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
import router from '@/router/Router';


export default {
    name: 'FailedPage',
    methods: {
        goBack() {
            router.push('/');
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>