<template>
  <div class="relative inline-block text-left" ref="dropdownRef">
    <button
      id="dropdownAvatarNameButton"
      class="flex items-center font-lato font-normal text-[16px] leading-[24px] text-lightText dark:text-darkText rounded-full focus:outline-none"
      type="button"
      @click="toggleDropdown"
    >
      <div class="flex items-center">
        <span class="text-lightText dark:text-darkText">{{ name }}</span>
        <img src="@/assets/icons/settings-icon.svg" alt="Settings Icon" class="w-5 h-5 ml-2" />
      </div>
    </button>    
    <div
      v-if="dropdownOpen"
      class="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-lightBackgroundContainer dark:bg-darkBackgroundContainer border border-lightBorder dark:border-darkBorder shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <div class="p-4">
        <p class="font-lato font-semibold text-[16px] leading-[24px] text-lightText dark:text-darkText">{{ name }}</p>
        <p class="font-lato font-normal text-[14px] leading-[24px] text-lightText dark:text-darkText truncate">{{ mail }}</p>
      </div>
      <div class="py-1">
        <button
          v-show="roles && roles.includes('Administrator')"
          @click="openSettingsModal"
          class="items-center justify-between w-full px-4 py-2 font-lato font-normal text-[16px] leading-[24px] text-lightText dark:text-darkText hover:text-customPurple hover:dark:text-customPurple"
        >
          <span class="flex items-center">
            <img src="@/assets/icons/settings-icon.svg" alt="Admin Settings Icon" class="w-5 h-5 mr-2" />
            {{ $t('UserAvatar_AdminSettings') }}
          </span>
        </button>
        <button
          @click="toggleTheme"
          class="flex items-center justify-between w-full px-4 py-2 font-lato font-normal text-[16px] leading-[24px] text-lightText dark:text-darkText hover:text-customPurple hover:dark:text-customPurple"
        >
        <span class="flex items-center">
        <svg
          v-if="!isDarkMode"
          class="w-5 h-5 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 3v1m0 16v1m8.66-8.66l.7.7M4.64 4.64l.7.7m15.02 10.02l-.7.7M5.34 19.36l-.7.7M21 12h-1M4 12H3m9-9a9 9 0 100 18 9 9 0 000-18z"
          />
        </svg>
        <svg
          v-else
          class="w-5 h-5 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21.752 15.002A9.718 9.718 0 0112 21a9.718 9.718 0 01-9.752-9.002 7.5 7.5 0 0011.002-9.752A9.718 9.718 0 0112 3a9.718 9.718 0 019.752 9.002 7.5 7.5 0 000 3.002z"
          />
        </svg>

        {{ isDarkMode ? $t("Theme_Pref_Dark") : $t("Theme_Pref_Light") }}
      </span>
        </button>
        <button
          @click="toggleLanguageDropdown"
          class="flex items-center justify-between w-full px-4 py-2 font-lato font-normal text-[16px] leading-[24px] text-lightText dark:text-darkText hover:text-customPurple hover:dark:text-customPurple"
        >
          <span class="flex items-center">
            {{ $t("UserAvatar_Language") }}
          </span>
          <svg
            class="w-5 h-5 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          v-if="languageDropdownOpen"
          class="absolute right-0 mt-2 w-44 origin-top-right bg-lightBackgroundContainer dark:bg-darkBackgroundContainer border border-lightBorder dark:border-darkBorder shadow-lg"
        >
          <ul class="py-2 font-lato font-normal text-[14px] leading-[24px] text-lightText dark:text-darkText">
            <li>
              <a
                href="#"
                @click="changeLanguage('es')"
                :class="locale == 'es' ? 'text-customPurple font-bold' : ''"
                class="block px-4 py-2 hover:text-customPurple hover:dark:text-customPurple"
              >
                Español (ES)
              </a>
            </li>
            <li>
              <a
                href="#"
                @click="changeLanguage('en')"
                :class="locale == 'en' ? 'text-customPurple font-bold' : ''"
                class="block px-4 py-2 hover:text-customPurple hover:dark:text-customPurple"
              >
                English (US)
              </a>
            </li>
          </ul>
        </div>
        <a
          href="#"
          @click="logOut"
          class="flex items-center px-4 py-2 font-lato font-normal text-[16px] leading-[24px] text-lightText dark:text-darkText hover:text-customPurple hover:dark:text-customPurple"
        >
          <img
            src="@/assets/icons/signout-icon.svg"
            alt="Sign Out Icon"
            class="w-5 h-5 mr-2"
          />
          {{ $t("UserAvatar_SignOut") }}
        </a>
      </div>
    </div>
  </div>
  <teleport to="body">
    <SettingsModal 
        v-if="roles && roles.includes('Administrator')"
        v-show="showSettingsModal"
        @close="closeSettingsModal" 
        ref="settingsModalRef"
    />
  </teleport>
</template>

<script setup>
import { ref, computed, reactive, onMounted, defineEmits, onBeforeUnmount } from 'vue';
import { useMsal } from '../../composables/useMsal';
import { useUserInfo } from '../../composables/useUserInfo';
import { callMsGraphProfilePhoto } from '../../utils/MsGraphApiCall';
import { loginRequest } from '../../authConfig';
import { useI18n } from 'vue-i18n';
import SettingsModal from '../modals/SettingsModal.vue';

const { locale } = useI18n();
const { accounts, instance } = useMsal();
const { roles } = useUserInfo();
const dropdownRef = ref(null); 
const showSettingsModal = ref(false);
const dropdownOpen = ref(false);
const languageDropdownOpen = ref(false);
const isDarkMode = ref(false);
const settingsModalRef = ref(null);

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const toggleLanguageDropdown = () => {
  languageDropdownOpen.value = !languageDropdownOpen.value;
};

onMounted(() => {
  const savedTheme = localStorage.getItem('theme');
  if (savedTheme === 'dark' || (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    isDarkMode.value = true;
    document.documentElement.classList.add('dark');
  } else {
    isDarkMode.value = false;
    document.documentElement.classList.remove('dark');
  }
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const toggleTheme = () => {
  isDarkMode.value = !isDarkMode.value;
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark');
    localStorage.setItem('theme', 'dark');
  } else {
    document.documentElement.classList.remove('dark');
    localStorage.setItem('theme', 'light');
  }
};

const name = computed(() => {
  return accounts.value.length > 0 ? accounts.value[0].name : '';
});

const mail = computed(() => {
  return accounts.value.length > 0 ? accounts.value[0].username : '';
});

const state = reactive({
  resolved: false,
  data: null,
});

const getGraphData = async () => {
  const accounts = instance.getAllAccounts();
  if (accounts.length === 0) {
    await instance.loginRedirect(loginRequest);
    return;
  }

  try {
    const response = await instance.acquireTokenSilent(loginRequest);
    const graphData = await callMsGraphProfilePhoto(response.accessToken);
    state.data = graphData;
    state.resolved = true;
  } catch (e) {
    console.error('Error fetching graph data:', e);
  }
};

const emit = defineEmits(['onChangedLanguage']);

const openSettingsModal = async () => {
    showSettingsModal.value = true;

    await settingsModalRef.value.getSettings();
};

const closeSettingsModal = () => {
    showSettingsModal.value = false;
};

const changeLanguage = (langCode) => {
  locale.value = langCode;
  languageDropdownOpen.value = false;
  emit('onChangedLanguage');
};

const logOut = async () => {
  await instance.logoutRedirect();
};
</script>
