<template>
  <div class="scroll-down-container">
    <button
      type="button"
      :class="[
        'fixed left-1/2 bottom-40 transform -translate-x-1/2 font-medium rounded-full text-sm p-1 text-center inline-flex items-center transition-opacity duration-300 z-[9999]',
        scrolling ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none',
        'bg-lightBackgroundContainer dark:bg-darkBorder hover:bg-customPurple hover:dark:bg-customPurple shadow-lg'
      ]"
      @click="scrollToBottom"
    >
      <img src="@/assets/icons/scroll-to-bottom-icon.svg" alt="Scroll to Bottom Icon" class="w-5 h-5" />
      <span class="sr-only">Scroll to bottom</span>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const scrolling = ref(false);

const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth',
  });
};

const handleScroll = () => {
  const scrollPosition = window.innerHeight + window.scrollY;
  const scrollHeight = document.body.scrollHeight;

  if (scrollPosition < scrollHeight - 5) {
    scrolling.value = true;
  } else {
    scrolling.value = false;
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  handleScroll();
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped>
.scroll-down-container {
  background-color: transparent;
}
</style>
