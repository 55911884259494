<template>
  <div 
    :class="[
      'relative col-span-8 flex flex-col items-center justify-center py-8 w-full max-w-screen-lg mx-auto transition-colors duration-300',
      hasChatResponses ? 'mt-5' : 'mt-40'
    ]"
  >
    <div 
      v-if="!hasChatResponses" 
      :class="[
        'font-lato font-semibold text-[28px] leading-[32px] mb-4 w-full text-left text-lightText dark:text-darkText transition-colors duration-300',
        asideOpen ? 'px-0' : 'px-12'
      ]"
    >
      {{ title }}
    </div>
    <div 
      v-if="!hasChatResponses" 
      :class="[
        'font-lato font-normal text-[14px] leading-[24px] mb-0 w-full text-left text-lightText dark:text-darkText transition-colors duration-300',
        asideOpen ? 'px-0' : 'px-12'
      ]"
    >
      {{ subtitle }}
    </div>
    <button
      v-if="!hasChatResponses"
      @click="$emit('edit')"
      :class="['absolute top-4 cursor-pointer text-customPurple hover:text-customPurpleHover dark:text-customPurple dark:hover:text-customPurpleHover transition-colors duration-300',
        asideOpen ? 'right-0' : 'right-12'
      ]"
    >
      {{ $t('Body_Section1_EditLabel') }}
    </button>
    <div v-if="!hasChatResponses" class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full max-w-4xl mt-8 transition-colors duration-300">
      <button
        v-for="(question, index) in translatedQuestions"
        :key="index"
        @click="$emit('selectQuestion', question)"
        class="px-6 py-4 text-left rounded-md text-base leading-[24px] font-normal font-lato border-2 bg-lightBackgroundContainer dark:bg-darkBorder text-lightText dark:text-darkText border-transparent hover:border-customPurple"
      >
        {{ question.text }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  title: String,
  subtitle: String,
  hasChatResponses: Boolean,
  preselectedQuestions: Array,
  asideOpen: Boolean,
});

const { t } = useI18n();

const translatedQuestions = computed(() => {
  return props.preselectedQuestions.map(question => ({
    ...question,
    text: question.text || t(question.textKey),
  }));
});
</script>
