<template>
  <div class="flex flex-col justify-center items-center p-4 bg-lightBackground dark:bg-darkBackground w-full transition-colors duration-300">
    <div class="w-full max-w-[120vh] p-4 rounded-sm bg-lightBackground dark:bg-darkBackground transition-colors duration-300">
      <div class="flex justify-between items-center mb-4 transition-colors duration-300">
        <CategoryDropdown 
          :categories="translatedCategories" 
          :selectedCategory="selectedCategory"
          @update:category="selectCategory" 
        />
        <QuestionDropdown 
          :questions="preselectedQuestions" 
          @selectQuestion="handleSelectQuestion"
          @edit="editQuestions" 
        />
      </div>
      <ChatComponent
        :userText="user_text"
        :isResponding="responding"
        @update:userText="updateUserText"
        @send="send"
        @stop="stop"
        class="w-full"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import ChatComponent from '@/components/chat/ChatComponent.vue';
import CategoryDropdown from './CategoryDropdown.vue';
import QuestionDropdown from './QuestionDropdown.vue';

const props = defineProps({
  categories: Array, 
  user_text: { type: String, default: '' },
  selectedCategory: {
    type: Object,
    default: () => ({ id: 'general', text: 'General' })
  },
  responding: Boolean,
  preselectedQuestions: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['selectQuestion', 'edit', 'update:category', 'send', 'stop', 'update:userText']);

const { t, locale } = useI18n();

const selectedCategory = ref(props.selectedCategory);

const translatedCategories = computed(() => {
  return props.categories.map(category => ({
    ...category,
    text: t(category.text) 
  }));
});

const selectCategory = (option) => {
  selectedCategory.value = option;
  emit('update:category', option);
};

watch(() => props.selectedCategory, (newVal) => {
  selectedCategory.value = newVal;
});

watch(locale, () => {
  selectedCategory.value = props.selectedCategory;
});

const handleSelectQuestion = (question) => {
  emit('update:userText', question.text);

  const category = props.categories.find(cat => cat.id === question.categoryId);
  if (category) {
    const translatedCategory = { ...category, text: t(category.text) };
    selectedCategory.value = translatedCategory;
    emit('update:category', translatedCategory);
  } else {
    console.warn('Categoría no encontrada para la pregunta seleccionada.');
  }

  send(question.text);
};

const editQuestions = () => {
  emit('edit');
};

const updateUserText = (newText) => {
  if (typeof newText === 'string') {
    emit('update:userText', newText);
  } else {
    console.error('El valor de newText no es un string:', newText);
  }
};

const send = (message) => {
  if (typeof message === 'string' && message.trim()) {
    const categoryToSend = {
      id: selectedCategory.value.id,
      text: t(selectedCategory.value.text)
    };
    emit('send', {
      message: message.trim(),
      category: categoryToSend,  
    });
  } else {
    console.error('El valor de message no es un string:', message);
  }
};

const stop = () => {
  emit('stop');
};

onMounted(() => {
  if (!selectedCategory.value) {
    selectedCategory.value = {
      id: 'general',
      text: t('General')
    };
    emit('update:category', selectedCategory.value);
  }
});
</script>
